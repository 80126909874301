import FormDialog from "../Components/ContactForm";
import Details from "../Components/Details";
import GetStarted from "../Components/GetStarted";
import Guide from "../Components/Guide";
import HeroMaain from "../Components/Hero/HeroMaain";
import BasicTabs from "../Components/ImageGallery";
import Properties from "../Components/Properties";

const Home = () =>{
return(
    <>
    <HeroMaain />
     <Guide />
      <Properties />
      <BasicTabs />
      <Details />
      <FormDialog />
      <GetStarted />
      </>
)
}
export default Home;