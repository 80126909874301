import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import Hero from './Hero';
import HeroDeskTop from './HeroDeskTop';


const HeroMaain = () => {
   const isMd = useMediaQuery('(min-width:600px)') 
  return (
    <>
    {isMd ? <HeroDeskTop /> : <Hero />}
    </>
  )
}

export default HeroMaain