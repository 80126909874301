import { OPEN_FORM, INSERT_CONTACTFORM_CLEAR, INSERT_CONTACTFORM_FAILED, INSERT_CONTACTFORM_SUCCESS, INSERT_CONTACTFORM_REQUEST } from "../action/ContactAction";

const initialState ={
    openForm: false,
}
export default (state = initialState, action) =>{
    switch (action.type) {
        case OPEN_FORM:
            return{
                ...state,
                openForm: action.payload,
            }
        default:
            return state;
    }
}