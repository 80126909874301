import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware, { runSaga } from 'redux-saga';
import contactReducer from './reducer/contactReducer';

const rootReducers = combineReducers({
    contactReducer: contactReducer,
})

const sagaMiddleware = createSagaMiddleware();



export const store = createStore(rootReducers, applyMiddleware(sagaMiddleware));

// runSaga();