import React from 'react';
import { Container, Typography, TextField, Button, Box, styled, } from '@mui/material';
// import { Box, styled, Typography } from "@mui/material";

const useStyles = styled(Box)(({ theme }) => ({
    container: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    submitButton: {
        marginTop: theme.spacing(2),
    },
}));

const Contact = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="sm" className={classes.container}>
            <Typography variant="h4" className={classes.title}>
                Contact Us
            </Typography>
            <form className={classes.form}>
                <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Message"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                />
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.submitButton}
                >
                    Submit
                </Button>
            </form>
        </Container>
    );
};

export default Contact;
