import React from "react"
import Navbar from "./Components/Navbar"
import Footer from "./Components/Footer"

const Layout =({children}) =>{
return(
    <React.Fragment>
    <Navbar />
    {children}
    <Footer />
    </React.Fragment>
)}
export default Layout