// import * as React from 'react';
// import Box from '@mui/material/Box';
// import ImageList from '@mui/material/ImageList';
// import ImageListItem from '@mui/material/ImageListItem';
// import { Typography, Divider } from '@mui/material';

// export default function ImageGallery() {
//   return (
//     <Box  sx={{ mt: 5, width: '100vw', height: 450, overflowY: 'scroll' }}>
//         <Typography variant='h3' sx={{textAlign: 'center'}}>Our Projects</Typography>
//         <Divider variant="middle" />
//         <Divider variant="middle" />
//       <ImageList variant="masonry" cols={3} gap={8}>
//         {itemData.map((item) => (
//           <ImageListItem key={item.img}>
//             <img
//               src={`${item.img}?w=248&fit=crop&auto=format`}
//               srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
//               alt={item.title}
//               loading="lazy"
//             />
//           </ImageListItem>
//         ))}
//       </ImageList>
//     </Box>
//   );
// }

// const itemData = [
//   {
//     img: 'https://images.unsplash.com/photo-1549388604-817d15aa0110',
//     title: 'Bed',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1525097487452-6278ff080c31',
//     title: 'Books',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1523413651479-597eb2da0ad6',
//     title: 'Sink',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3',
//     title: 'Kitchen',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1588436706487-9d55d73a39e3',
//     title: 'Blinds',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622',
//     title: 'Chairs',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1530731141654-5993c3016c77',
//     title: 'Laptop',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1481277542470-605612bd2d61',
//     title: 'Doors',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1517487881594-2787fef5ebf7',
//     title: 'Coffee',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee',
//     title: 'Storage',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62',
//     title: 'Candle',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1519710164239-da123dc03ef4',
//     title: 'Coffee table',
//   },
// ];

import React, {useState} from 'react';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
import {Masonry} from '@mui/lab';
// import { styled } from '@mui/material/styles';

import {Tabs, Tab, Box} from '@mui/material'

import interior1 from '../media/Interior/interior1.jpg'
import interior2 from '../media/Interior/interior2.jpg'
import interior3 from '../media/Interior/interiorketchen1.jpg'
import interior4 from '../media/Interior/interiorketchen.jpg'
import interior5 from  '../media/Interior/interiorketchen2.jpg'
import interior6 from '../media/Interior/interiorCracker.jpg'
import interior7 from '../media/Interior/bedroom.jpg'
import interior8 from '../media/Interior/interiorwardrobe9.jpg'
import interior9 from '../media/Interior/interiorwardrobe7.jpg'
import interior10 from '../media/Interior/temple2.jpg'
import interior11 from '../media/Interior/tvunits2.jpg'
import interior12 from '../media/NewImage/image1.jpg'
import interior13 from '../media/NewImage/image2.jpg'
import interior14 from '../media/NewImage/image3.jpg'
import interior15 from '../media/NewImage/image4.jpg'
import interior16 from '../media/NewImage/image5.jpg'
import interior17 from '../media/NewImage/image6.jpg'
import interior18 from '../media/NewImage/image7.jpg'
import interior19 from '../media/NewImage/IMG-20240121-WA0022.jpg'
import interior20 from '../media/NewImage/IMG-20240121-WA0023.jpg'
import interior21 from '../media/NewImage/IMG-20240121-WA0024.jpg'
import interior22 from '../media/NewImage/IMG-20240121-WA0025.jpg'
import interior23 from '../media/NewImage/IMG-20240121-WA0026.jpg'
import interior24 from '../media/NewImage/IMG-20240121-WA0027.jpg'
import interior25 from '../media/NewImage/IMG-20240121-WA0028.jpg'
import interior26 from '../media/NewImage/IMG-20240121-WA0031.jpg'
import interior27 from '../media/NewImage/IMG-20240121-WA0032.jpg'
import interior28 from '../media/NewImage/IMG-20240121-WA0033.jpg'
import interior29 from '../media/NewImage/IMG-20240121-WA0034.jpg'
import interior30 from '../media/NewImage/IMG-20240121-WA0035.jpg'
import interior31 from '../media/NewImage/IMG-20240121-WA0036.jpg'
import interior32 from '../media/NewImage/IMG-20240121-WA0037.jpg'
import interior33 from '../media/NewImage/IMG-20240121-WA0038.jpg'
import interior34 from '../media/NewImage/IMG-20240121-WA0039.jpg'
import interior35 from '../media/NewImage/IMG-20240121-WA0040.jpg'
import interior36 from '../media/NewImage/IMG-20240121-WA0041.jpg'
import interior37 from '../media/NewImage/IMG-20240121-WA0042.jpg'
import interior38 from '../media/NewImage/IMG-20240121-WA0043.jpg'
import interior39 from '../media/NewImage/IMG-20240121-WA0044.jpg'
import interior40 from '../media/NewImage/IMG-20240121-WA0045.jpg'
import interior41 from '../media/NewImage/IMG-20240121-WA0046.jpg'
import interior42 from '../media/NewImage/IMG-20240121-WA0047.jpg'
import interior43 from '../media/NewImage/IMG-20240121-WA0048.jpg'
import interior44 from '../media/NewImage/IMG-20240121-WA0049.jpg'
import interior45 from '../media/NewImage/IMG-20240121-WA0050.jpg'
import interior46 from '../media/NewImage/IMG-20240121-WA0051.jpg'
import interior47 from '../media/NewImage/IMG-20240121-WA0052.jpg'
import interior48 from '../media/NewImage/IMG-20240121-WA0053.jpg'
import interior49 from '../media/NewImage/IMG-20240121-WA0054.jpg'
import interior50 from '../media/NewImage/IMG-20240121-WA0055.jpg'
import interior51 from '../media/NewImage/IMG-20240121-WA0056.jpg'
import interior52 from '../media/NewImage/IMG-20240121-WA0057.jpg'
import interior53 from '../media/NewImage/IMG-20240121-WA0058.jpg'
import interior54 from '../media/NewImage/IMG-20240121-WA0059.jpg'
import interior55 from '../media/NewImage/IMG-20240121-WA0060.jpg'
import interior56 from '../media/NewImage/IMG-20240121-WA0061.jpg'
import interior57 from '../media/NewImage/IMG-20240121-WA0062.jpg'
import interior58 from '../media/NewImage/IMG-20240121-WA0063.jpg'
import interior59 from '../media/NewImage/IMG-20240121-WA0064.jpg'
import interior60 from '../media/NewImage/IMG-20240121-WA0065.jpg'
import interior61 from '../media/NewImage/IMG-20240121-WA0066.jpg'
import interior62 from '../media/NewImage/IMG-20240121-WA0067.jpg'
import interior63 from '../media/NewImage/IMG-20240121-WA0068.jpg'
import interior64 from '../media/NewImage/IMG-20240121-WA0069.jpg'
import interior65 from '../media/NewImage/IMG-20240121-WA0070.jpg'
import interior66 from '../media/NewImage/IMG-20240121-WA0071.jpg'
import interior67 from '../media/NewImage/IMG-20240121-WA0072.jpg'
import interior68 from '../media/NewImage/IMG-20240121-WA0073.jpg'
import interior69 from '../media/NewImage/IMG-20240121-WA0074.jpg'
import interior70 from '../media/NewImage/IMG-20240121-WA0075.jpg'

import  Construction1  from '../media/construction/IMG-20230812-WA0025.jpg';
import  Construction2 from '../media/construction/IMG-20230812-WA0026.jpg';
import Construction3 from '../media/construction/IMG-20230812-WA0027.jpg'
import Construction4 from '../media/construction/IMG-20230812-WA0028.jpg'
import Construction5 from '../media/construction/IMG-20230812-WA0029.jpg'
import Construction6 from '../media/construction/IMG-20230812-WA0030.jpg'
import Construction7 from '../media/construction/IMG-20230812-WA0031.jpg'
import Construction8 from '../media/construction/IMG-20230812-WA0032.jpg'
import Construction9 from '../media/construction/IMG-20230812-WA0033.jpg'
import Construction10 from '../media/construction/IMG-20230812-WA0034.jpg'
import Construction11 from '../media/NewImage/IMG-20240121-WA0029.jpg'
import Construction12 from '../media/NewImage/IMG-20240121-WA0030.jpg'

export default function BasicTabs() {
  const [value, setValue] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
console.log(value, 'value')
  return (
  <>
    <Box sx={{ width: '100%', display:'flex', justifyContent: 'center', alignItems:'center' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="interiors"value={1} />
          <Tab label="Constructions" value={2} />
        </Tabs>
    </Box>
    <Box sx={{ margin:2}}>
    { value === 1 && 
      <Masonry columns={3} spacing={1.3}>
        {interiors.map((item, index) => (
          <div key={index}>
            <img
              src={`${item.img}?w=162&auto=format`}
              srcSet={`${item.img}?w=162&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
              style={{
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                display: 'block',
                width: '100%',
              }}
            />
          </div>
        ))}
      </Masonry>}
      { value === 2 && 
      <Masonry columns={3} spacing={1.3}>
        {Constructions.map((item, index) => (
          <div key={index}>
            <img
              src={`${item.img}?w=162&auto=format`}
              srcSet={`${item.img}?w=162&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
              style={{
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                display: 'block',
                width: '100%',
              }}
            />
          </div>
        ))}
      </Masonry>}
    </Box>
    {/* </Box> */}
    </>
  );
}

const interiors = [
  {
    img: interior1,
    title: 'Fern',
  },
  {
    img: interior2,
    title: 'Snacks',
  },
  {
    img: interior3,
    title: 'Mushrooms',
  },
  {
    img: interior4,
    title: 'Tower',
  },
  {
    img: interior5,
    title: 'Fern',
  },
  {
    img: interior6,
    title: 'Snacks',
  },
  {
    img: interior7,
    title: 'Mushrooms',
  },
  {
    img: interior8,
    title: 'Tower',
  },
  {
    img: interior9,
    title: 'Snacks',
  },
  {
    img: interior10,
    title: 'Mushrooms',
  },
  {
    img: interior11,
    title: 'Tower',
  },
  {
    img: interior12,
    title: 'Tower1',
  },
  {
    img: interior13,
    title: 'Tower2',
  },
  {
    img: interior14,
    title: 'Tower3',
  },
  {
    img: interior15,
    title: 'Tower4',
  },
  {
    img: interior16,
    title: 'Tower5',
  },
  {
    img: interior17,
    title: 'Tower6',
  },
  {
    img: interior18,
    title: 'Tower7',
  },
  {
    img: interior19,
    title: 'Image8',
  },
  {
    img: interior20,
    title: 'Image9',
  },
  {
    img: interior21,
    title: 'Image10',
  },
  {
    img: interior22,
    title: 'Image11',
  },
  {
    img: interior23,
    title: 'Image12',
  },
  {
    img: interior24,
    title: 'Image13',
  },
  {
    img: interior25,
    title: 'Image14',
  },
  {
    img: interior26,
    title: 'Image15',
  },
  {
    img: interior27,
    title: 'Image16',
  },
  {
    img: interior28,
    title: 'Image17',
  },
  {
    img: interior29,
    title: 'Image18',
  },
  {
    img: interior30,
    title: 'Image19',
  },
  {
    img: interior31,
    title: 'Image20',
  },
  {
    img: interior32,
    title: 'Image21',
  },
  {
    img: interior33,
    title: 'Image22',
  },
  {
    img: interior34,
    title: 'Image23',
  },
  {
    img: interior35,
    title: 'Image24',
  },
  {
    img: interior36,
    title: 'Image25',
  },
  {
    img: interior37,
    title: 'Image26',
  },
  {
    img: interior38,
    title: 'Image27',
  },
  {
    img: interior39,
    title: 'Image28',
  },
  {
    img: interior40,
    title: 'Image29',
  },
  {
    img: interior41,
    title: 'Image30',
  },
  {
    img: interior42,
    title: 'Image31',
  },
  {
    img: interior43,
    title: 'Image32',
  },
  {
    img: interior44,
    title: 'Image33',
  },
  {
    img: interior45,
    title: 'Image34',
  },
  {
    img: interior46,
    title: 'Image35',
  },
  {
    img: interior47,
    title: 'Image36',
  }, 
  {
    img: interior48,
    title: 'Image37',
  },
  {
    img: interior49,
    title: 'Image38',
  },
  {
    img: interior50,
    title: 'Image39',
  },
  {
    img: interior51,
    title: 'Image40',
  },
  {
    img: interior52,
    title: 'Image41',
  },
  {
    img: interior53,
    title: 'Image42',
  },
  {
    img: interior54,
    title: 'Image43',
  },
  {
    img: interior55,
    title: 'Image44',
  },
  {
    img: interior56,
    title: 'Image45',
  },
  {
    img: interior57,
    title: 'Image46',
  },
  {
    img: interior58,
    title: 'Image47',
  },
  {
    img: interior59,
    title: 'Image48',
  },
  {
    img: interior60,
    title: 'Image49',
  },
  {
    img: interior61,
    title: 'Image50',
  },
  {
    img: interior62,
    title: 'Image51',
  },
  {
    img: interior63,
    title: 'Image52',
  },
  {
    img: interior64,
    title: 'Image53',
  },
  {
    img: interior65,
    title: 'Image54',
  },
  {
    img: interior66,
    title: 'Image55',
  },
  {
    img: interior67,
    title: 'Image56',
  },
  {
    img: interior68,
    title: 'Image57',
  },
  {
    img: interior69,
    title: 'Image58',
  },
  {
    img: interior70,
    title: 'Image59',
  }
];

const Constructions =[
  {img: Construction1,
  title: 'Constructions1'},
  {img: Construction2,
    title: 'Constructions1'},
    {img: Construction3,
      title: 'Constructions1'},
      {img: Construction4,
        title: 'Constructions1'},
        {img: Construction5,
          title: 'Constructions1'},
          {img: Construction6,
            title: 'Constructions1'},
            {img: Construction7,
              title: 'Constructions1'},
              {img: Construction8,
                title: 'Constructions1'},
                {img: Construction9,
                  title: 'Constructions1'},
                  {img: Construction10,
                    title: 'Constructions1'},
                    {img: Construction11,
                      title: 'Constructions1'},
                      {img: Construction12,
                        title: 'Constructions1'},
]