import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { open_Form } from '../redux/action/ContactAction';

export default function FormDialog() {
  const getOpen = useSelector((state) => state.contactReducer)
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    size: '',
    phone: null,
  })

  const handleClose = () => {
    dispatch(open_Form(false))
  };

  useEffect(() => {
    if (getOpen.openForm === true) {
  setOpen(getOpen.openForm)
}else if(getOpen.openForm === false) {
  setOpen(getOpen.openForm)
}
  }, [getOpen.openForm])

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Contact Us</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name-id"
            label="your name"
            type="email"
            onChange={(e) => setUserData({...userData, name: e.target.value})}
            fullWidth
            variant="outlined"
          />
          <TextField
            autoFocus
            margin="dense"
            id="email-id"
            label="Email Address"
            onChange={(e) => setUserData({...userData, email: e.target.value})}
            type="email"
            fullWidth
            variant="outlined"
          />
           <TextField
            autoFocus
            margin="dense"
            id="Number"
            label="Phone Number"
            onChange={(e) => setUserData({...userData, phone: e.target.value})}
            type="number"
            fullWidth
            variant="outlined"
          />
         <TextField
            autoFocus
            margin="dense"
            id="Number"
            label="Your Property Size"
            onChange={(e) => setUserData({...userData, size: e.target.value})}
            type="text"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
          disabled={userData.name === '' || userData.email === '' || userData.phone === null || userData.size === ''}
           onClick={handleClose}
           >Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
