import { Box, styled, Typography } from "@mui/material";
import React from "react";
import home from '../media/homeDesing.png'
import warrenty from '../media/warrent1.png'
import delivery from '../media/delivery_1350253.png'
import experience from '../media/experience.png'


const Guide = () => {
  const GuidesBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-around",
    width: "70%",
    // marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0",
      flexDirection: "column",
    },
  }));

  const GuideBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1, 0, 1, 0),
    },
  }));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      id='guide'
    >
      {/* <div
        style={{
          width: "5%",
          height: "5px",
          backgroundColor: "#000339",
          margin: "0 auto",
        }}
      ></div> */}

      <Typography
        variant="h3"
        sx={{ fontSize: "24px", fontWeight: "bold", color: "#000339", my: 1 }}
      >
        why choose Reflex?
      </Typography>

      {/* <CustomBox>
        <Typography
          variant="body2"
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            color: "#5A6473",
            textAlign: "center",
          }}
        >
          Everything you need to know when you want to buy, rent or sell - All
          in one place
        </Typography>
      </CustomBox> */}

      <GuidesBox>
        <GuideBox>
          <img src={home} alt="buyIcon" width={'50px'} height={'50px'}/>
          <Typography
            variant="caption"
            sx={{
              fontWeight: "500",
              color: "#3B3c45",
              my: 1,
            }}
          >
            100+ Happy Customer
          </Typography>
          {/* <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: "bold", fontSize: "14px", color: "#0689FF" }}
            >
              How to buy
            </Typography>
            <ArrowRightAltIcon style={{ color: "#0689FF" }} />
          </Box> */}
        </GuideBox>

        <GuideBox>
          <img src={warrenty} alt="buyIcon" width={'50px'} height={'50px'}/>
          <Typography
            variant="caption"
            sx={{
              fontWeight: "500",
              color: "#3B3c45",
              my: 1,
            }}
          >
           flat 10 year warrenty
          </Typography>
          {/* <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: "bold", fontSize: "14px", color: "#0689FF" }}
            >
              How to rent
            </Typography>
            <ArrowRightAltIcon style={{ color: "#0689FF" }} />
          </Box> */}
        </GuideBox>

        <GuideBox>
          <img src={delivery} alt="buyIcon" width={'50px'}  height={'50px'}/>
          <Typography
            variant="caption"
            sx={{
              fontWeight: "500",
              color: "#3B3c45",
              my: 1,
            }}
          >
            45 days fastest handover
          </Typography>
        </GuideBox>
        <GuideBox>
          <img src={experience} alt="buyIcon" width={'50px'}  height={'50px'}/>
          <Typography
            variant="caption"
            sx={{
              fontWeight: "500",
              color: "#3B3c45",
              my: 1,
            }}
          >
           10+ years experience
          </Typography>
        </GuideBox>
      </GuidesBox>
    </Box>
  );
};

export default Guide;
