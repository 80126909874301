
import img1 from "./media/Interior/tvunits3.jpg"
import img2 from "./media/Interior/interiorwardrobe.jpg"
import img3 from "./media/Interior/bedroom.jpg"
import img4 from "./media/Interior/interiorketchen1.jpg"

export const properties = [
  {
    id: "1",
    img: img1,
    price: "TV Unit",
    address: "8502 Preston Rd. Inglewood, Maine 98280",
    bedrooms: 2,
    bathrooms: 2,
    space: 2000,
  },

  {
    id: "2",
    img: img2,
    price: "WardRobe",
    address: "WardRobe",
    bedrooms: 3,
    bathrooms: 2,
    space: 2300,
  },

  {
    id: "3",
    img: img3,
    price: "BedRoom",
    address: "2917 23rd St",
    bedrooms: 4,
    bathrooms: 3,
    space: 3000,
  },
  {
    id: "4",
    img: img4,
    price: "Modular Kitchen",
    address: "2917 23rd St",
    bedrooms: 4,
    bathrooms: 3,
    space: 3000,
  },
];
