export const INSERT_CONTACTFORM = 'INSERT_CONTACTFORM'
export const INSERT_CONTACTFORM_REQUEST = 'INSERT_CONTACTFORM_REQUEST'
export const INSERT_CONTACTFORM_SUCCESS = 'INSERT_CONTACTFORM_SUCCESS'
export const INSERT_CONTACTFORM_FAILED = 'INSERT_CONTACTFORM_FAILED'
export const INSERT_CONTACTFORM_CLEAR = 'INSERT_CONTACTFORM_CLEAR'

export const OPEN_FORM = 'OPEN_FORM'

export const insert_ContactForm = (payload) => {
    return {
        type: INSERT_CONTACTFORM_REQUEST,
        payload: payload
    }
}

export const insert_ContactForm_Success = (payload) => {
    return {
        type: INSERT_CONTACTFORM_SUCCESS,
        payload: payload
    }
}
export const insert_ContactForm_Failed = (payload) => {
    return {
        type: INSERT_CONTACTFORM_FAILED,
        payload: ''
    }
}
export const insert_ContactForm_Clear = (payload) => {
    return {
        type: INSERT_CONTACTFORM_CLEAR,
        payload: ''
    }
}

export const open_Form = (payload) =>{
    return {
        type: OPEN_FORM,
        payload: payload
    }
}