import React from "react";
import videoFile from '../../media/hrosection.mp4';
import ReactPlayer from "react-player";
import './Hero.css'


const Hero = () => {

  function scrollToGuide() {
    document.getElementById('guide').scrollIntoView();
  }


  return (
      <div className="hero-container">
        <div className="video-container">
          <ReactPlayer
            url={videoFile}
            playing={true}
            loop={true}
            muted={true}
            width="100%"
            height="100%"
            controls={false}
            playsinline
            onPlay={() => {
              // This code will run when the video starts playing
              console.log('Video started playing');
              // You can add any custom logic you need here
            }}
          />
        </div>
        <div className="content-container">
          {/* Add your hero content here */}
          <h1>Welcome to Reflex</h1>
          <p>Looking for expert guidance to design your home?</p>
          <button onClick={scrollToGuide}>Explore More</button>
        </div>
      </div>
    );

};

export default Hero;

